import * as React from 'react';

interface ISsrInitDataContext {
  ssr: boolean;
  location: {
    href: string,
  } | undefined;
  serverCompactMode: boolean | undefined;
  hasLoginToken: boolean | undefined;
}

export const SsrInitDataContext = React.createContext<Partial<ISsrInitDataContext>>({});
