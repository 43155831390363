import React, { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useFetch } from 'src/lib/FetchContext';
import { SsrInitDataContext } from 'src/lib/SsrInitDataContext';

interface ILoginResult {
  success?: boolean;
  error?: boolean;
}

interface ILoginContext {
  isLoggedIn: boolean;
  youHaveBeenLoggedOut: boolean;
  clearYouHaveBeenLoggedOut: () => void;
  login: (email: string, password: string) => Promise<ILoginResult>;
  logout: () => Promise<void>;
}

export const LoginContext = React.createContext<Partial<ILoginContext>>({});

export const LoginContextConsumer = LoginContext.Consumer;
export const LoginContextProvider = ({ children }) => {
  const { fetch } = useFetch();

  const { ssr, hasLoginToken } = useContext(SsrInitDataContext);
  let initLoginStatus = false;
  if(ssr) {
    initLoginStatus = hasLoginToken;
  } else {
    initLoginStatus = Cookies.get('RIAC_RESTKEY') ? true : false;
  }
  const [isLoggedIn, innerSetIsLoggedIn] = React.useState(initLoginStatus);

  const [youHaveBeenLoggedOut, setYouHaveBeenLoggedOut] = React.useState(false);
  let validateInterval = -1;
  const setIsLoggedIn = (loggedIn: boolean) => {
    innerSetIsLoggedIn(loggedIn);
    clearInterval(validateInterval);
    if(loggedIn) {
      validateInterval = setInterval(validateAccount, 15000);
    } else {
      validateInterval = -1;
    }
  };

  const clearRestKeyCookie = (): void => {
    Cookies.remove('RIAC_RESTKEY', { domain: 'www.rockislandauction.com' });
    Cookies.remove('RIAC_RESTKEY', { domain: '.rockislandauction.com' });
    Cookies.remove('RIAC_RESTKEY', { domain: 'rockislandauction.com' });
    Cookies.remove('RIAC_RESTKEY');
  };

  const validateAccount = async (first = false) => {
    try {
      const validateResponse = await fetch('/api/account/validate', { credentials: 'include' });
      const validateResult = await validateResponse.json();
      if(!validateResult.Success) {
        clearRestKeyCookie();
        setIsLoggedIn(false);
        if(!first) {
          setYouHaveBeenLoggedOut(true);
        }
        return false;
      }
      if(!isLoggedIn) {
        setIsLoggedIn(true);
        setYouHaveBeenLoggedOut(false);
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const login = async (email: string, password: string) => {
    clearRestKeyCookie();
    const loginResponse = await fetch('/api/account/login', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    const loginResult = await loginResponse.json();
    if(loginResult.Success) {
      Cookies.set('RIAC_RESTKEY', loginResult.RESTKEY, { expires: 365, path: '/' });
      setIsLoggedIn(true);
      setYouHaveBeenLoggedOut(false);
      return { success: true };
    } else {
      return { error: loginResult.Error || 'An unkown error has occured. Please contact customer support' };
    }
  };

  const logout = async () => {
    await fetch('/api/account/logout', {
      credentials: 'include',
      method: 'POST',
    });
    clearRestKeyCookie();
    setTimeout(() => {
      if(location.pathname.includes('/account')) {
        window.location.assign('/login');
      } else location.reload();
    }, 1);
  };

  useEffect(() => {
    if(isLoggedIn) {
      setIsLoggedIn(true);
    }
    validateAccount(true);
  }, []);

  return (
    <LoginContext.Provider value={{
      isLoggedIn,
      youHaveBeenLoggedOut,
      clearYouHaveBeenLoggedOut: () => setYouHaveBeenLoggedOut(false),
      login,
      logout,
    }}>
      {children}
    </LoginContext.Provider>
  );
};
