import styled from 'styled-components';

import {
  CloseButtonModal,
  CloseButtonModalDialog,
  CloseButtonModalTitle,
  ModalClose,
  CloseButtonModalProps,
} from '../BaseComponents/Modals/CloseButtonModal';
import { theme } from '../../theme';

export {
  CloseButtonModalBase as FlexModalBase,
  CloseButtonModalContent as FlexModalContent,
  CloseButtonModalDialog as FlexModalDialog,
  CloseButtonModalTitle as FlexModalTitle,
  ModalClose as FlexModalCloseButton,
} from '../BaseComponents/Modals/CloseButtonModal';

export type FlexModalProps = {
  fullHeight?: boolean,
  minWidth?: string,
  maxWidth?: string,
  maxHeight?: string,
  padding?: string,
  backgroundColor?: string,
} & CloseButtonModalProps;


/* Base style is additive to ModalAriaBase */
export const FlexModal = styled(CloseButtonModal)<FlexModalProps>`
  flex-flow: column nowrap;
  background-color: rgba(13, 13, 13, .8);
  align-items: center;
  justify-content: center;

  ${CloseButtonModalDialog} {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    
    min-width: ${(props) => props.maxWidth ? props.maxWidth : '50%'};
    max-width: ${(props) => props.maxWidth ? props.maxWidth : '100%'};
    
    ${theme.desktop} {
      min-width: ${(props) => props.maxWidth ? props.maxWidth : 'initial'};
      max-width: ${(props) => props.maxWidth ? props.maxWidth : '750px'};
    }

    ${(props) => `max-height: ${props.maxHeight ? props.maxHeight : '100%'};`}
    ${(props) => `padding: ${props.padding ? props.padding : '24px'};`}
    ${(props) => props.fullHeight && 'height: 100%;'}

    ${(props) => `background-color: ${props.backgroundColor ? props.backgroundColor : 'white'};`}
    
    ${(props) => `color: ${props.textColor ? props.textColor : 'rgba(0, 0, 0, 0.8)'};`}
    box-shadow:
      0 1px 4px rgba(0,0,0,.09),
      0 3px 8px rgba(0,0,0,.09),
      0 4px 13px rgba(0,0,0,.13);    
  }

  ${CloseButtonModalTitle} {
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    padding-top: 1em;
  }

  ${ModalClose} {
    position: absolute;
    top: 8px;
    right: 8px;
  
    padding: 12px;
    
    color: #0d0d0d;
    user-select: none;
    
    font-size: 18px;
    line-height: 0.7;
    text-align: center;
    
    border: 1px solid transparent;
    border-radius: 3px;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:focus {
      border-color: black;
    }
  }
`;
