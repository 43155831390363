import { OverlayProvider } from '@react-aria/overlays';
import { LinkContext, ModalScrollContextProvider } from '@riac/design-system';
import PropTypes from 'prop-types';
import React, { Suspense, useContext } from 'react';
import { Switch } from 'react-router';
import { BrowserRouter, Redirect, Route, StaticRouter } from 'react-router-dom';
import { Footer } from 'src/components/Footer/Footer';
import { Header } from 'src/components/Header/Header';
import { ScrollToTop } from 'src/components/scrollToTop';
import { ServerSideRenderingError } from 'src/components/ServerSideRenderingError';
import { YouHaveBeenLoggedOut } from 'src/components/YouHaveBeenLoggedOut';
import { CrispChat } from 'src/components/CrispChat';
import { IntersectionObserverContextProvider } from '@riac/core';
import { AccountHeaderContextProvider } from 'src/lib/AccountHeaderContext';
import { CatalogHistoryContextProvider } from 'src/lib/CatalogHistoryContext';
import { ScrollContextProvider } from 'src/lib/ScrollContext';
import { SsrInitDataContext } from 'src/lib/SsrInitDataContext';
import { WishlistActionContextProvider } from 'src/lib/WishlistActionContext';
import { LoadableErrorBoundary } from 'src/LoadableErrorBoundary';
import { LoginContextProvider } from 'src/lib/LoginContext';
import { GhostPages } from 'src/pages/GhostPages';
import { Page404 } from 'src/pages/Page404';
import { UserItemContextProvider } from 'src/lib/UserItemContext';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './styles/global';
import { theme } from '@riac/design-system';
import ErrorBoundary from 'src/lib/ErrorBoundary';
import { Spinner } from '@riac/design-system';
import { LazyLoadContextProvider } from '@riac/core';
import { AgeVerificationCheck } from './components/AgeVerificationCheck/AgeVerificationCheck';
import { PageLoader } from 'src/lib/PageLoader';
import { AsyncCatalogPage } from 'src/pages/CatalogPage/AsyncCatalogPage';
import { AsyncDetailPage } from 'src/pages/DetailPage/AsyncDetailPage';
import { AsyncAboutUsPage } from 'src/pages/AboutUsPage/AsyncAboutUsPage';
import { AsyncCareersPage } from 'src/pages/CareersPage/AsyncCareersPage';
import { AsyncConsignmentPage } from 'src/pages/ConsignmentPage/AsyncConsignmentPage';
import { AsyncConsignmentProcessPage } from 'src/pages/ConsignmentProcessPage/AsyncConsignmentProcessPage';
import { AsyncGhostPostIndexPage } from 'src/pages/GhostPostIndexPage/AsyncGhostPostIndexPage';
import { AsyncGhostPostSinglePage } from 'src/pages/GhostPostSinglePage/AsyncGhostPostSinglePage';
import { AsyncNewsAndEventsPage } from 'src/pages/NewsAndEventsPage/AsyncNewsAndEventsPage';
import { AsyncConsignmentThePastThatDrivesUsPage } from
  'src/pages/ConsignmentThePastThatDrivesUsPage/AsyncConsignmentThePastThatDrivesUsPage';
import { AsyncConsignmentWhoWeArePage } from 'src/pages/ConsignmentWhoWeArePage/AsyncConsignmentWhoWeArePage';
import { AsyncAuctionsPage } from 'src/pages/AuctionsPage/AsyncAuctionsPage';
import { AsyncAuctionsSinglePage } from 'src/pages/AuctionsSinglePage/AsyncAuctionsSinglePage';
import landingPages from 'src/pages/CatalogPage/landing-pages';
import { AsyncCatalogLandingPage } from 'src/pages/CatalogPage/CatalogLandingPage/AsyncCatalogLandingPage';
import { AsyncContactPage } from 'src/pages/ContactPage/AsyncContactPage';
import { AsyncCreateAccountPage } from 'src/pages/CreateAccountPage/AsyncCreateAccountPage';
import { AsyncFAQPage } from 'src/pages/FAQPage/AsyncFAQPage';
import { AsyncFAQSinglePage } from 'src/pages/FAQPage/FAQSinglePage/AsyncFAQSinglePage';
import { AsyncLocationBedfordPage } from 'src/pages/LocationsPage/LocationBedfordPage/AsyncLocationBedfordPage';
import { AsyncAdCampaignsPage } from 'src/pages/AdCampaigns/AsyncAdCampaignsPage';
import { AsyncAdCampaignsSinglePage } from 'src/pages/AdCampaigns/AsyncAdCampaignsSinglePage';
import { AsyncConsignmentPastSuccessPage } from 'src/pages/ConsignmentPastSuccessPage/AsyncConsignmentPastSuccessPage';
import { AsyncConsignmentGettingStartedPage } from
  'src/pages/ConsignmentGettingStartedPage/AsyncConsignmentGettingStartedPage';
import '../../design-system/src/global.css';
import WhiskeyAndWarStories from 'src/pages/DataCollectionLandingPage/WhiskeyAndWarStories';

const AsyncAccountPage = ({ ...props }) => <PageLoader
  id='src/pages/AccountPage/AccountPage'
  componentImport={async () => (await import('src/pages/AccountPage/AccountPage'))}
  {...props}/>;
const AsyncOrderCatalogPage = ({ ...props }) => <PageLoader
  id='src/pages/OrderCatalogPage/OrderCatalogPage'
  componentImport={() => import('src/pages/OrderCatalogPage/OrderCatalogPage') as any}
  {...props}/>;

const AsyncFrontPage = ({ ...props }) => <PageLoader
  id='src/pages/FrontPage/FrontPage'
  componentImport={() => import('src/pages/FrontPage/FrontPage')}
  {...props}/>;
const AsyncTermsAndConditionsPage = ({ ...props }) => <PageLoader
  id='src/pages/TermsAndConditionsPage'
  componentImport={() => import('src/pages/TermsAndConditionsPage')}
  {...props}/>;
const AsyncLoginPage = ({ ...props }) => <PageLoader
  id='src/pages/LoginPage'
  componentImport={() => import('src/pages/LoginPage')}
  {...props}/>;
const AsyncPasswordResetPage = ({ ...props }) => <PageLoader
  id='src/pages/AccountPage/PasswordReset/PasswordReset'
  componentImport={() => import('src/pages/AccountPage/PasswordReset/PasswordReset')}
  {...props}/>;
const AsyncGunPriceEstimatorPage = ({ ...props }) => <PageLoader
  id='src/pages/HowMuchIsYourGunWorth'
  componentImport={() => import('src/pages/HowMuchIsYourGunWorth')}
  {...props}/>;
const AsyncStyleGuidePage = ({ ...props }) => <PageLoader
  id='src/pages/StyleGuide'
  componentImport={() => import('src/pages/StyleGuide')}
  {...props}/>;
const AsyncWishlistImport = ({ ...props }) => <PageLoader
  id='src/pages/AccountPage/WishlistImport/WishlistImport'
  componentImport={() => import('src/pages/AccountPage/WishlistImport/WishlistImport')}
  {...props}/>;
const AsyncAccountInfoValidatePage = ({ ...props }) => <PageLoader
  id='src/pages/AccountPage/AccountInfoValidatePage'
  componentImport={() => import('src/pages/AccountPage/AccountInfoValidatePage')}
  {...props}/>;

const Providers = ({ children }: React.PropsWithChildren): JSX.Element => (
// <SocketContextProvider>
  <OverlayProvider>  {/* React-Aria */}
    <LinkContext.Provider value={{ domainName: window.DOMAIN_NAME }}>
      <LoginContextProvider>
        <AccountHeaderContextProvider>
          <UserItemContextProvider>
            <ModalScrollContextProvider>
              <ScrollContextProvider>
                <IntersectionObserverContextProvider>
                  <LazyLoadContextProvider>
                    <ThemeProvider theme={theme}>
                      {children}
                    </ThemeProvider>
                  </LazyLoadContextProvider>
                </IntersectionObserverContextProvider>
              </ScrollContextProvider>
            </ModalScrollContextProvider>
          </UserItemContextProvider>
        </AccountHeaderContextProvider>
      </LoginContextProvider>
    </LinkContext.Provider>
  </OverlayProvider>
// </SocketContextProvider>
);
Providers.propTypes = {
  children: PropTypes.node,
};

const AppDisplayContainer = styled.div`
    display: flex;
    min-height: 101vh;
    min-height: calc(100vh + 1px);
    flex-direction: column;
`;

const AppFooterSpacer = styled.div`
    flex: 100000;
`;

export const AppRoutes = (): JSX.Element => {
  return <Route path='/:p0?/:p1?/:p2?/:p3?/:p4?/:p5?' render={({ match }) => (
    <LoadableErrorBoundary onError={(error, info) => console.error(error, info)}>
      <Switch>
        {/*
          * Items have attached QR codes that the client can scan
          * at home once they received their won item.  The QR Code
          * encodes http://www.rockislandauction.com/qr/cs/:auctionId/:lotNumber
          * This endpoint is a hook to redirect them, as where we want these
          * links to lead may change over time
        */}
        <Route path='/qr/cs/' render={({ location }) => {
          return <Redirect to={'/detail' + location.pathname.slice(6)}/>;
        }}/>
        <Route exact path='/whiskey-and-war-stories' render={() => <WhiskeyAndWarStories/>}/>
        <Route path='/pricesrealized'>
          <Redirect to='/gun-auctions'/>
        </Route>
        <Route exact path='/about-us'
          render={() => <AsyncAboutUsPage/>}
        />
        <Route exact path='/gun-auctions'
          render={() => <AsyncAuctionsPage/>}
        />
        <Route exact path='/category' render={() => <Redirect to='/catalog'/>}/>
        <Route
          exact
          path='/(catalog|category)/deringer'
          render={() => <Redirect to='/category/derringer'/>}
        />
        <Route exact path='/faq'
          render={() => <AsyncFAQPage/>}
        />
        <Route exact path='/riac-blog'
          render={(match) => <AsyncGhostPostIndexPage {...match}/>}
        />
        <Route exact path='/news'
          render={(match) => <AsyncGhostPostIndexPage {...match}/>}
        />
        <Route exact path='/login' render={() => <AsyncLoginPage/>}/>
        <Route exact path='/login/password-reset' render={() => <AsyncPasswordResetPage/>}/>
        <Route exact path='/create-account/:code?' render={() => <AsyncCreateAccountPage/>}/>
        <Route exact path='/past-auction-successes' render={() => <AsyncConsignmentPastSuccessPage/>}/>
        <Route exact path='/'
          render={() => <AsyncFrontPage/>}
        />
        <Route path='/(catalog|detail|category)'>
          <WishlistActionContextProvider>
            <CatalogHistoryContextProvider>
              <Switch>
                <Route path='/(catalog|category)'>
                  <Switch>
                    {landingPages.map((landingPage) => {
                      return (
                        <Route
                          key={landingPage.slug}
                          exact
                          path={`/(catalog|category)/${landingPage.slug}`}
                          render={() => <AsyncCatalogLandingPage basicLandingPage={landingPage}/>}
                        />
                      );
                    })}
                    <Route>
                      <Switch>
                        <Route path='/catalog/:p0?/:p1?/:p2?/:p3?/:p4?/:p5?'>
                          <AsyncCatalogPage/>
                        </Route>
                        <Page404/>
                      </Switch>
                    </Route>
                  </Switch>
                </Route>
                <Route exact path='/detail/:auctionid/:lotnumber'>
                  <AsyncDetailPage/>
                </Route>
                <Route exact path='/detail/:auctionid/:lotnumber/:slug'>
                  <AsyncDetailPage/>
                </Route>
                <Page404/>
              </Switch>
            </CatalogHistoryContextProvider>
          </WishlistActionContextProvider>
        </Route>
        <Route path='/account/validate/:type?/:success?/:code?'
          render={({ match }) => <AsyncAccountInfoValidatePage match={match}/>}
        />
        <Route path={'/account/wishlist/shared/:wlid?'}
          render={(match) => (
            <WishlistActionContextProvider>
              <AsyncWishlistImport {...match}/>
            </WishlistActionContextProvider>
          )}
        />
        <Route path='/account'
          render={() => <AsyncAccountPage/>}
        />
        <GhostPages match={match}>
          <Switch>
            <Route path='/order-catalog'
              component={({ match }) => <AsyncOrderCatalogPage match={match}/>}
            />
            <Route exact path='/careers'
              component={() => <AsyncCareersPage/>}
            />
            <Route exact path='/news-and-events'
              render={() => <AsyncNewsAndEventsPage/>}
            />
            <Route exact path='/faq/:slug'
              render={(match) => <AsyncFAQSinglePage {...match}/>}
            />
            <Route exact path='/riac-blog/:title'
              render={(match) => <AsyncGhostPostSinglePage {...match}/>}
            />
            <Route exact path='/news/:title'
              render={(match) => <AsyncGhostPostSinglePage {...match}/>}
            />
            <Route exact path='/adcampaign'
              render={() => <AsyncAdCampaignsPage/>}
            />
            <Route exact path='/adcampaign/:slug'
              render={() => <AsyncAdCampaignsSinglePage/>}
            />
            <Route exact path='/consignment/getting-started'
              render={(match) => <AsyncConsignmentGettingStartedPage {...match}/>}
            />
            <Route exact path='/terms-and-conditions'
              render={() => <AsyncTermsAndConditionsPage/>}
            />
            <Route path='/location/bedford'
              render={() => <AsyncLocationBedfordPage/>}
            />
            <Route path='/gun-auctions/:slug'
              render={(match) => <AsyncAuctionsSinglePage {...match}/>}
            />
            <Route exact path='/consignment'
              render={() => <AsyncConsignmentPage/>}
            />
            <Route exact path='/consignment/the-past-that-drives-us'
              render={() => <AsyncConsignmentThePastThatDrivesUsPage/>}
            />
            <Route exact path='/consignment/who-we-are'
              render={() => <AsyncConsignmentWhoWeArePage/>}
            />
            <Route exact path='/consignment/consignment-process'
              render={() => <AsyncConsignmentProcessPage/>}
            />
            <Route exact path='/consignment/how-much-is-your-gun-worth/:guntype?/:mfg?/:model?'
              render={(match) => <AsyncGunPriceEstimatorPage {...match}/>}
            />
            <Route exact path='/style-guide'
              render={() => <AsyncStyleGuidePage/>}
            />
            <Route exact path='/about-us'
              render={() => <AsyncAboutUsPage/>}
            />
            <Route exact path='/contact' render={() => <AsyncContactPage/>}/>
            <Route render={() => <Page404/>}/>
          </Switch>
        </GhostPages>
      </Switch>
    </LoadableErrorBoundary>
  )}/>;
};

export const App = (): JSX.Element => {
  const { location, ssr } = useContext(SsrInitDataContext);
  const Router: any = (ssr && StaticRouter) || BrowserRouter; // TODO: Bad any but they don't provide a better type

  return (<>
    <Providers>
      <GlobalStyles/>
      <Router
        context={{}}
        location={location && location.href}
      >
        <AppDisplayContainer>
          <ServerSideRenderingError/>
          <YouHaveBeenLoggedOut/>
          <Header/>
          <ScrollToTop/>
          <AgeVerificationCheck/>
          <div>
            <ErrorBoundary>
              <Suspense fallback={<Spinner/>}>
                <AppRoutes/>
              </Suspense>
            </ErrorBoundary>
          </div>
          <AppFooterSpacer/>
          <Footer/>
          <CrispChat/>
        </AppDisplayContainer>
      </Router>
    </Providers>
  </>
  );
};
