import React from 'react';
import { PageLoader } from 'src/lib/PageLoader';

export const AsyncAuctionsSinglePage = ({ ...props }): JSX.Element => (
  <PageLoader
    id='src/pages/AuctionsSinglePage/AuctionsSinglePage'
    componentImport={async () => (await import('src/pages/AuctionsSinglePage/AuctionsSinglePage'))}
    head={({ fetch, location }) => async () => {
      console.log(location.pathname);
      const cur = location.pathname.split('/')[2].split('-');
      const id = cur[cur.length - 1];
      const req = await fetch(`/api/auction/auctionByID/${id}`);
      const res = await req.json();
      if(res.length < 1) {
        return {
          error404: true,
          title: 'Auction Not Found',
        };
      }
      const auction = res[0];
      return {
        title: auction?.Title,
        description: auction?.Title, // TODO: Description
        structuredData: `{
          "@context": "https://schema.org",
          "@type": "Event",
          "name": "${auction?.Title}",
          "startDate": "${auction?.StartDate}T${auction?.StartTime}-05:00",
          "endDate": "${auction?.EndDate}",
          "eventAttendanceMode": "https://schema.org/MixedEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "location": [
            {
              "@type": "VirtualLocation",
              "url": "https://www.rockislandauction.com/live"
            },
            {
              "@type": "Place",
              "name": "Rock Island Auction Company",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "${auction?.LocAddress}",
                "addressLocality": "${auction?.LocCity}",
                "postalCode": "${auction?.LocZip}",
                "addressRegion": "${auction?.LocState}",
                "addressCountry": "US"
              }
            }
          ],
          "description": "${/* TODO: Event description */''}",
          "organizer": {
            "@type": "Organization",
            "name": "Rock Island Auction Company",
            "url": "https://www.rockislandauction.com"
          }
        }`,
      };
    }}
    {...props}
  />
);
