import styled from 'styled-components';

import {
  RadioGroupAria,
} from '../BaseComponents/Inputs/RadioGroupAria';

export const RadioGroup = styled(RadioGroupAria)`
  display: flex;
  flex-direction: column;
`;
